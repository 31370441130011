<template>
    <div class="theorymatching" v-loading="isLoading">
        <div class="main_box">
            <div class="header_wrapper">
                <div class="header_left">
                    <div class="input_item">
                        <span class="header_span">配合比编号：</span>
                        <el-input
                            class="item_input"
                            v-model="theoryFormula.theory_formula_number"
                            placeholder="自动生成"
                            disabled
                        ></el-input>
                    </div>
                    <div class="input_item">
                        <span class="mustFill header_span">配合比名称：</span>
                        <el-input
                            class="item_input"
                            v-model="theoryFormula.theory_formula_name"
                            placeholder="请输入内容"
                            :disabled="is_publish"
                        ></el-input>
                    </div>
                    <div class="input_item" style="margin-right: 0 !important">
                        <span class="mustFill header_span">配方类型：</span>
                        <el-select
                            style="width: 77%"
                            v-model="theoryFormula.formula_type"
                            placeholder="—请选择—"
                            @change="formulaTypeChange"
                            :disabled="is_publish || isEdit == false"
                        >
                            <el-option
                                v-for="item in formula_typeData"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="main_wrapper">
                <div class="main_top">
                    <div class="head_title_container">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">产品标示</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <div class="product_marking_wrapper">
                        <table
                            class="check-set-tb"
                        >
                            <tr>
                                <th><span class="mustFill">强度等级：</span></th>
                                <td>
                                    <el-select
                                        v-model="theoryFormula.strength_grade"
                                        placeholder="—请选择—"
                                        @change="selectGet('strength_grade')"
                                        :disabled="is_publish || isEdit == false"
                                    >
                                        <el-option
                                            v-for="item in ProductIdentification.qddj.options"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select>
                                </td>
                                <th>抗渗等级：</th>
                                <td>
                                    <el-select
                                        v-model="theoryFormula.impervious"
                                        placeholder="—请选择—"
                                        @change="selectGet('impervious')"
                                        :disabled="is_publish || isEdit == false"
                                    >
                                        <el-option
                                            v-for="item in ProductIdentification.ksdj.options"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="8" class="tr_border_th">
                                    <div class="tr_border"></div>
                                </th>
                            </tr>
                            <tr>
                                <th>抗冻等级：</th>
                                <td>
                                    <el-select
                                        v-model="theoryFormula.antifreeze"
                                        placeholder="—请选择—"
                                        @change="selectGet('antifreeze')"
                                        :disabled="is_publish || isEdit == false"
                                    >
                                        <el-option
                                            v-for="item in ProductIdentification.kddj.options"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select>
                                </td>
                                <th>抗折要求：</th>
                                <td>
                                    <el-select
                                        v-model="theoryFormula.flexural"
                                        placeholder="—请选择—"
                                        @change="selectGet('flexural')"
                                        :disabled="is_publish || isEdit == false"
                                    >
                                        <el-option
                                            v-for="item in ProductIdentification.kzyq.options"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="8" class="tr_border_th">
                                    <div class="tr_border"></div>
                                </th>
                            </tr>
                            <tr>
                                <th>特殊要求：</th>
                                <td colspan="7">
                                    <el-select
                                        style="width: 100% !important"
                                        v-model="theoryFormula.select_special_require"
                                        multiple
                                        placeholder="—请选择—"
                                        @change="selectGet('special_require')"
                                        :disabled="is_publish || isEdit == false"
                                    >
                                        <el-option
                                            v-for="item in ProductIdentification.tsyq.options"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code"
                                        ></el-option>
                                    </el-select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="head_title_container">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">基本信息</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <div class="basic_info_wrapper">
                        <div class="basic_info_box">
                            <table
                                class="check-set-tb"
                            >
                                <tr>
                                    <th><span class="mustFill">浇注方式：</span></th>
                                    <td>
                                        <el-radio-group v-model="theoryFormula.pouring_way">
                                            <el-radio
                                                :label="1"
                                                :disabled="is_publish || isEdit == false"
                                            >
                                                泵送
                                            </el-radio>
                                            <el-radio
                                                :label="2"
                                                :disabled="is_publish || isEdit == false"
                                            >
                                                非泵送
                                            </el-radio>
                                        </el-radio-group>
                                    </td>
                                    <th><span class="mustFill">坍落度：</span></th>
                                    <td style="display: flex">
                                        <el-input
                                            v-model="theoryFormula.slump"
                                            style="width:1.15rem"
                                            :disabled="is_publish || isEdit == false"
                                        ></el-input>
                                        <i>±</i>
                                        <el-input
                                            v-model="theoryFormula.slump_flaot"
                                            style="width:1.2rem"
                                            :disabled="is_publish || isEdit == false"
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="8" class="tr_border_th">
                                        <div class="tr_border"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>最大粒径：</th>
                                    <td>
                                        <el-input
                                            v-model="theoryFormula.max_particle_size"
                                            placeholder="请输入内容"
                                            :disabled="is_publish || isEdit == false"
                                        ></el-input>
                                    </td>
                                    <th>水泥规格：</th>
                                    <td>
                                        <el-input
                                            v-model="theoryFormula.cement_specifications"
                                            placeholder="请输入内容"
                                            :disabled="is_publish || isEdit == false"
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="8" class="tr_border_th">
                                        <div class="tr_border"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th><span class="mustFill">施工季节：</span></th>
                                    <td>
                                        <el-select
                                            v-model="theoryFormula.season"
                                            placeholder="—请选择—"
                                            :disabled="is_publish || isEdit == false"
                                        >
                                            <el-option
                                                v-for="item in seasonData"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </td>
                                    <th>搅拌时间(s)：</th>
                                    <td>
                                        <el-input
                                            v-model="theoryFormula.mixing_time"
                                            placeholder="请输入内容"
                                            :disabled="is_publish || isEdit == false"
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="8" class="tr_border_th">
                                        <div class="tr_border"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>容重：</th>
                                    <td>
                                        <el-input
                                            disabled
                                            v-model="theoryFormula.design_volume_weight"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </td>
                                    <th>水胶比：</th>
                                    <td>
                                        <el-input
                                            disabled
                                            v-model="theoryFormula.water_binder_ratio"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="8" class="tr_border_th">
                                        <div class="tr_border"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>砂率(%)：</th>
                                    <td>
                                        <el-input
                                            disabled
                                            v-model="theoryFormula.sand_rate"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </td>
                                    <th>技术要求：</th>
                                    <td>
                                        <el-input
                                            v-model="theoryFormula.specification"
                                            placeholder="请输入内容"
                                            :disabled="is_publish || isEdit == false"
                                        ></el-input>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="main_bottom">
                    <div class="main_header" v-if="isEdit">
                        <div class="btn_wrapper">
                            <el-button
                                type="primary"
                                @click="addChange"
                                :disabled="is_publish"
                                class="el-icon-plus"
                            >
                                新增
                            </el-button>
                        </div>
                    </div>
                    <div class="main_content">
                        <div class="content_left">
                            <el-table
                                :data="tableData"
                                border
                                :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
                            >
                                <el-table-column label="原材料分类">
                                    <template slot-scope="scope">
                                        <el-select
                                            v-model="scope.row.stock_category_binding"
                                            placeholder="—请选择—"
                                            @change="selectRawMaterials(scope.row.stock_category_binding,scope.$index)"
                                            :disabled="is_publish || isEdit == false"
                                        >
                                            <el-option
                                                v-for="(item, index) in stockCategoryData"
                                                :key="index"
                                                :label="item.name"
                                                :value="item.code"
                                            >
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="原材料子类">
                                    <template slot-scope="scope">
                                        <el-select
                                            v-model="scope.row.material_child_binding"
                                            placeholder="—请选择—"
                                            @change="selectRawMaterialSubclass(scope.row,scope.$index)"
                                            :disabled="is_publish || isEdit == false"
                                        >
                                            <el-option
                                                v-for="(item, index) in scope.row.materialChildData"
                                                :key="index"
                                                :label="item.name"
                                                :value="item.code"
                                            >
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="原材料规格">
                                    <template slot-scope="scope">
                                        <el-select
                                            v-model="scope.row.material_binding"
                                            placeholder="—请选择—"
                                            @change="materialChange(scope.row,scope.$index)"
                                            :disabled="is_publish || isEdit == false"
                                        >
                                            <el-option
                                                v-for="(item, index) in scope.row.materialData"
                                                :key="index"
                                                :label="item.name"
                                                :value="item.code"
                                            >
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="配比用量">
                                    <template slot-scope="scope">
                                        <el-input
                                            placeholder="请输入"
                                            min="0"
                                            type="number"
                                            v-model="scope.row.theory_quantity"
                                            @mousewheel.native.prevent
                                            @change="calculation"
                                            :class="{'iconfont iconyujing': theoryQuantity(scope.row)}"
                                            :disabled="is_publish"
                                        >
                                        </el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="最小值">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.theory_quantity_min"
                                            @mousewheel.native.prevent
                                            min="0"
                                            type="number"
                                            placeholder="请输入"
                                            :disabled="is_publish"
                                            :class="{'iconfont iconyujing': Minimum(scope.row)}"
                                        ></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="最大值">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.theory_quantity_max"
                                            @mousewheel.native.prevent
                                            min="0"
                                            type="number"
                                            placeholder="请输入"
                                            :disabled="is_publish"
                                            :class="{'iconfont iconyujing': Maximum(scope.row)}"
                                        ></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="配比用量_运营区" v-if="isEdit == false">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.theory_quantity_yyq"
                                            disabled
                                        >
                                        </el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="最小值_运营区" v-if="isEdit == false">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.theory_quantity_min_yyq"
                                            disabled
                                        ></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="最大值_运营区" v-if="isEdit == false">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.theory_quantity_max_yyq"
                                            disabled
                                        ></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="操作" v-if="isEdit">
                                    <template slot-scope="scope">
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            plain
                                            @click="lineAddChange(scope.row,scope.$index)"
                                            class="el-icon-plus"
                                            style="color:#409EFF"
                                            :disabled="is_publish"
                                        >
                                        </el-button>
                                        <el-button
                                            type="danger"
                                            size="mini"
                                            plain
                                            @click="deleteChange(scope.row,scope.$index)"
                                            class="el-icon-delete"
                                            style="color:red"
                                            :disabled="is_publish"
                                        >
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header_right">
            <el-button
                type="primary"
                @click="release"
                plain
                v-if="isSave && is_publish == false"
            >
                发布
            </el-button>
            <el-button
                type="primary"
                @click="theoryFormulaSave"
                plain
                v-if="is_publish == false"
            >
                保存
            </el-button>
        </div>
        <!-- <el-button
            type="primary"
            @click="theoryFormulaSave"
            v-if="is_publish == false"
        >
            保存
        </el-button>
        <el-button
            type="primary"
            @click="release"
            v-if="isSave && is_publish == false"
        >
            发布
        </el-button> -->
    </div>
</template>

<script>
import store from '@/store';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);

export default {
    // 理论配比
    name: 'theorymatching',
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            // 产品标识数据
            ProductIdentification: {
                // 强度等级
                qddj: {
                    checked: false,
                    options: [],
                },
                // 抗渗等级
                ksdj: {
                    checked: false,
                    options: [],
                },
                // 抗冻等级
                kddj: {
                    checked: false,
                    options: [],
                },
                // 抗折要求
                kzyq: {
                    checked: false,
                    options: [],
                },
                // 特殊要求
                tsyq: {
                    checked: false,
                    options: [],
                },
            },
            // 配方类型
            formula_typeData: [
                {
                    label: '混凝土',
                    value: 0,
                },
                {
                    label: '砂浆',
                    value: 1,
                },
                {
                    label: '润泵砂浆',
                    value: 3,
                },
            ],
            // 施工季节下拉数据
            seasonData: [
                {
                    label: '夏',
                    value: 2,
                },
                {
                    label: '冬',
                    value: 4,
                },
            ],
            radio2: 0,
            // 保存理论配比基本信息
            theoryFormula: {
                // 理论配比编号
                theory_formula_number: '',
                // 理论配比名称
                theory_formula_name: '',
                // 配方类型：0 混凝土 1 砂浆 3:润泵砂浆
                formula_type: 0,
                // 强度等级
                strength_grade: '',
                // 强度等级名称
                strength_grade_name: '',
                // 抗渗等级
                impervious: '',
                // 抗渗等级名称
                impervious_name: '',
                // 抗冻等级
                antifreeze: '',
                // 抗冻等级名称
                antifreeze_name: '',
                // 抗折要求
                flexural: '',
                // 抗折要求名称
                flexural_name: '',
                // 特殊要求
                special_require: '',
                // 特殊要求选中
                select_special_require: [],
                // 特殊要求名称
                special_require_name: '',
                // 浇筑方式:1:泵送；2:非泵
                pouring_way: 1,
                // 坍落度
                slump: 0,
                // 坍落度(±)
                slump_flaot: 0,
                // 施工季节：2 夏季；4 冬季
                season: 2,
                // 搅拌时间
                mixing_time: '',
                // 最大粒径
                max_particle_size: 0,
                // 水泥规格
                cement_specifications: '',
                // 设计容重
                design_volume_weight: 0,
                // 水胶比
                water_binder_ratio: 0,
                // 砂率
                sand_rate: 0,
                // 技术要求
                specification: '',
                // 是否发布
                is_publish: false,
            },
            // table 数据集合
            tableData: [],
            // 原材料名称select数据
            stockCategoryData: [],
            // 加载状态
            isLoading: false,
            // 是否已保存
            isSave: false,
            // 是否已发布
            is_publish: false,
            // 运营区是否可编辑
            isEdit: true,
        };
    },
    async created() {
        // 获取产品标示下面select的数据
        this.selectAll();
        // 获取原材料名称数据
        await this.getStockCategory();

        // 是否获取详情，编辑获取，新增不获取
        if (this.extr && Object.keys(this.extr).length > 0) {
            // 只读场景
            if (this.extr.isReadOnly) {
                this.is_publish = this.extr.isReadOnly;
            }
            if (this.extr.formula_number) {
                // 初始化页面数据
                const requestUrl = `/interfaceApi/production/theoryformula/formulawithentity/${this.extr.formula_number}`;
                this.getInitData(requestUrl);
            }
            if (this.extr.zu_h_id) {
                // 初始化页面数据
                // eslint-disable-next-line max-len
                const requestUrl = `/interfaceApi/production/theoryformula/formulawithentity/${this.extr.formula_number}?zu_h_id=${this.extr.zu_h_id}`;
                this.getInitData(requestUrl);
            }
        }
    },
    mounted() {

    },
    computed: {},
    methods: {
        // 编辑时获取保存的详情
        getInitData(requestUrl) {
            this.isLoading = true;
            this.tableData = [];
            this.$axios
                .get(requestUrl)
                .then(res => {
                    this.theoryFormula = res.theoryFormula;
                    this.tableData = res.theoryFormulaEntity;

                    this.tableData.forEach(item => {
                        item.stock_category_binding = item.stock_category_name;
                        item.material_child_binding = item.material_child_name;
                        // item.material_binding = item.material_name;
                        this.$set(item, 'material_binding', item.material_name);
                        this.stockCategoryData.forEach(k => {
                            // eslint-disable-next-line max-len
                            if ((k.group_code || '') !== '' && item.stock_category === k.group_code || (k.dic_code || '') !== '' && item.stock_category_dictcode === k.dic_code) {
                                this.$set(item, 'materialChildData', k.children);
                                k.children.forEach(j => {
                                    // eslint-disable-next-line max-len
                                    if ((j.group_code || '') !== '' && item.material_child_code === j.group_code || (j.dic_code || '') !== '' && item.material_child_dictcode === j.dic_code) {
                                        this.$set(item, 'materialData', j.children);
                                        this.$forceUpdate();
                                    }
                                });
                            }
                        });
                    });

                    // 是否以保存
                    if (this.theoryFormula.theory_formula_number !== '') {
                        this.isSave = true;
                    }
                    // 是否已发布
                    if (this.theoryFormula.is_publish) {
                        this.is_publish = true;
                    }

                    if (this.theoryFormula.formula_type === 1) {
                        // 获取强度等级下拉列表的数据
                        this.SelectData('005-SJ0', '0', 'qddj');
                    } else {
                        // 获取强度等级下拉列表的数据
                        this.SelectData_qddj();
                    }

                    if (this.theoryFormula.special_require !== '') {
                        this.theoryFormula.select_special_require = this.theoryFormula.special_require.split(',');
                    } else {
                        this.theoryFormula.select_special_require = [];
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 计算容重、水胶比、砂率
        calculation() {
            // 容重
            let design_volume_weight = 0;
            // 细骨料
            let fineAggregate = 0;
            // 粗骨料
            let coarseAggregate = 0;
            // 水
            let water = 0;
            // 粉料
            let powder = 0;
            this.tableData.forEach(item => {
                design_volume_weight += Number(item.theory_quantity).toFixed(2) * 100;
                if (item.stock_category_dictcode === '203') {
                    fineAggregate += Number(item.theory_quantity);
                }
                if (item.stock_category_dictcode === '204') {
                    coarseAggregate += Number(item.theory_quantity);
                }
                if (item.stock_category_dictcode === '207') {
                    water += Number(item.theory_quantity);
                }
                if (
                    item.stock_category_dictcode === '202'
                    || item.stock_category_dictcode === '205-FHL'
                    || item.stock_category_dictcode === '205-GHJ'
                    || item.stock_category_dictcode === '205-FMH'
                    || item.stock_category_dictcode === '205-GH0'
                    || item.stock_category_dictcode === '205-GZF'
                    || item.stock_category_dictcode === '205-KZF'
                    || item.stock_category_dictcode === '205-LZF'
                    || item.stock_category_dictcode === '205-SHF'
                ) {
                    powder += Number(item.theory_quantity);
                }
            });

            // 砂率
            if (fineAggregate > 0) {
                this.theoryFormula.sand_rate = NP.times(NP.divide(fineAggregate, NP.plus(fineAggregate, coarseAggregate)).toFixed(2), 100);
            } else {
                this.theoryFormula.sand_rate = 0;
            }

            // 水胶比
            if (water > 0 && powder > 0) {
                this.theoryFormula.water_binder_ratio = NP.divide(water, powder).toFixed(2);
            } else {
                this.theoryFormula.water_binder_ratio = 0;
            }

            // 容重
            this.theoryFormula.design_volume_weight = NP.divide(design_volume_weight, 100);
        },
        // 获取产品标示下面select的数据
        selectAll() {
            // 获取强度等级下拉列表的数据
            if (this.theoryFormula.formula_type === 1) {
                this.SelectData('005-SJ0', '0', 'qddj');
            } else {
                this.SelectData_qddj();
            }
            // 获取抗渗等级下拉列表的数据
            this.SelectData('501-KS0', '5', 'ksdj');
            // 获取抗冻等级下拉列表的数据
            this.SelectData('501-KD0', '5', 'kddj');
            // 获取抗折等级下拉列表的数据
            this.SelectData('501-KZ0', '5', 'kzyq');
            // 获取特殊要求下拉列表的数据
            this.SelectData('501-TX0', '5', 'tsyq');
        },

        // 选择配方类型触发的事件
        formulaTypeChange(val) {
            this.theoryFormula.strength_grade = '';
            if (val === 1) {
                // 获取强度等级下拉列表的数据
                this.SelectData('005-SJ0', '0', 'qddj');
            } else {
                // 获取强度等级下拉列表的数据
                this.SelectData_qddj();
            }
        },

        // 获取select的数据请求
        SelectData(code, type, obj) {
            this.$axios
                .get('/interfaceApi/production/dictionary/dropdownlist/' + code + '/' + type)
                .then(res => {
                    if (obj === 'qddj') {
                        this.ProductIdentification.qddj.options = res;
                    } else if (obj === 'ksdj') {
                        this.ProductIdentification.ksdj.options = res;
                    } else if (obj === 'kddj') {
                        this.ProductIdentification.kddj.options = res;
                    } else if (obj === 'kzyq') {
                        this.ProductIdentification.kzyq.options = res;
                    } else if (obj === 'tsyq') {
                        this.ProductIdentification.tsyq.options = res;
                    }
                });
        },

        // 获取“配方类型“选择”混凝土“、”润泵砂浆“时，强度等级的select的数据
        SelectData_qddj() {
            this.$axios
                .get('/interfaceApi/baseinfo/dictionary_config/gettree?parent_code=004')
                .then(res => {
                    const row = [];
                    if ((res || []).length > 0) {
                        res.forEach(item => {
                            row.push(...item.children);
                        });
                    }
                    this.ProductIdentification.qddj.options = row;
                });
        },

        // 产品标识name赋值
        selectGet(value) {
            const _that = this;
            if (value === 'strength_grade') {
                // 强度等级
                _that.ProductIdentification.qddj.options.forEach(item => {
                    if (item.code === _that.theoryFormula.strength_grade) {
                        _that.theoryFormula.strength_grade_name = item.name;
                    }
                });
            } else if (value === 'impervious') {
                // 抗渗等级
                _that.ProductIdentification.ksdj.options.forEach(item => {
                    if (item.code === _that.theoryFormula.impervious) {
                        _that.theoryFormula.impervious_name = item.name;
                    }
                });
            } else if (value === 'antifreeze') {
                // 抗冻等级
                _that.ProductIdentification.kddj.options.forEach(item => {
                    if (item.code === _that.theoryFormula.antifreeze) {
                        _that.theoryFormula.antifreeze_name = item.name;
                    }
                });
            } else if (value === 'flexural') {
                // 抗折要求
                _that.ProductIdentification.kzyq.options.forEach(item => {
                    if (item.code === _that.theoryFormula.flexural) {
                        _that.theoryFormula.flexural_name = item.name;
                    }
                });
            } else if (value === 'special_require') {
                // 特殊要求
                const selectData = _that.ProductIdentification.tsyq.options.filter(
                    item => _that.theoryFormula.select_special_require.some(ele => ele === item.code)
                );
                _that.theoryFormula.special_require_name = '';
                let selectName = '';
                selectData.forEach(item => {
                    selectName += item.name + ',';
                });
                selectName = selectName.substring(0, selectName.length - 1);
                _that.theoryFormula.special_require_name = selectName;
                this.$forceUpdate();
            }
        },

        // 获取原材料名称数据
        async getStockCategory() {
            this.tableData = [];
            this.isLoading = true;
            await this.$axios
                .get('/interfaceApi/baseinfo/business_extension/material_group_tree')
                .then(res => {
                    // 所有原材料数据
                    this.stockCategoryData = res;
                    // 初始化出7大类原材料数据
                    res.forEach(item => {
                        if (['水泥', '矿粉', '粉煤灰', '细骨料', '粗骨料', '水', '外加剂'].indexOf(item.name) > -1) {
                            this.tableData.push({
                                id: null,
                                // 原材料分类绑定字段
                                stock_category_binding: item.code,
                                // 原材料分类字典码
                                stock_category_dictcode: item.dic_code,
                                // 原材料分类编码
                                stock_category: item.group_code,
                                // 原材料分类名称
                                stock_category_name: item.name,

                                // 原材料子类绑定字段
                                material_child_binding: '',
                                // 原材料子类字典码
                                material_child_dictcode: '',
                                // 原材料子类编码
                                material_child_code: '',
                                // 原材料子类名称
                                material_child_name: '',
                                // 原材料子类待选数据
                                materialChildData: item.children,

                                // 原材料规格绑定字段
                                material_binding: '',
                                // 原材料规格编码
                                material_code: '',
                                // 原材料规格名称
                                material_name: '',
                                // 原材料规格待选数据
                                materialData: [],

                                // 配比用量
                                theory_quantity: 0,
                                // 配比用量最大值
                                theory_quantity_max: 0,
                                // 配比用量最小值
                                theory_quantity_min: 0,
                                // 货源地编码
                                source_goods_code: '',
                                // 货源地名称
                                source_goods_name: '',
                                // 排序
                                sort_num: 0,
                            });
                        }
                    });
                    // 计算容重、水胶比、砂率
                    this.calculation();
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择原材料分类
        selectRawMaterials(val, index) {
            const row = this.stockCategoryData.filter(item => {
                return item.code === val;
            });
            if (row.length > 0) {
                this.tableData[index].stock_category_name = row[0].name;
                this.tableData[index].stock_category_dictcode = row[0].dic_code;
                this.tableData[index].stock_category = row[0].group_code;

                this.tableData[index].material_child_binding = '';
                this.tableData[index].material_child_dictcode = '';
                this.tableData[index].material_child_code = '';
                this.tableData[index].material_child_name = '';

                this.tableData[index].material_binding = '';
                this.tableData[index].material_code = '';
                this.tableData[index].material_name = '';
                this.tableData[index].material_bg_code = '';
            }

            this.$set(this.tableData[index], 'materialChildData', row[0].children);
            this.$forceUpdate();
        },
        // 选择原材料子类
        selectRawMaterialSubclass(val, index) {
            const row = val.materialChildData.filter(item => {
                return item.code === val.material_child_binding;
            });
            if (row.length > 0) {
                this.tableData[index].material_child_name = row[0].name;
                this.tableData[index].material_child_dictcode = row[0].dic_code;
                this.tableData[index].material_child_code = row[0].group_code;

                this.tableData[index].material_binding = '';
                this.tableData[index].material_code = '';
                this.tableData[index].material_name = '';
                this.tableData[index].material_bg_code = '';
            }

            this.$set(this.tableData[index], 'materialData', row[0].children);
            this.$forceUpdate();
        },

        // 原材料规格选择触发的事件
        materialChange(val, index) {
            const row = val.materialData.filter(item => {
                return item.code === val.material_binding;
            });
            if (row.length > 0) {
                this.tableData[index].material_binding = row[0].code;
                this.tableData[index].material_code = row[0].dic_code;
                this.tableData[index].material_name = row[0].name;
                this.tableData[index].material_bg_code = row[0].group_code;
            }
        },
        // 删除
        deleteChange(row, index) {
            if (row.id) {
                this.$axios
                    .delete('/interfaceApi/production/theoryformula/deleteformulaentity/' + row.id)
                    .then(res => {
                        if (res) {
                            this.tableData.splice(index, 1);
                            // 计算容重、水胶比、砂率
                            this.calculation();
                        } else {
                            this.$message.error('删除失败');
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.tableData.splice(index, 1);
                // 计算容重、水胶比、砂率
                this.calculation();
            }
        },
        // 行内新增
        lineAddChange(row, index) {
            const newData = {
                id: null,
                // 原材料分类绑定字段
                stock_category_binding: '',
                // 原材料分类字典码
                stock_category_dictcode: row.stock_category_dictcode,
                // 原材料分类编码
                stock_category: row.stock_category,
                // 原材料分类名称
                stock_category_name: row.stock_category_name,

                // 原材料子类绑定字段
                material_child_binding: '',
                // 原材料子类字典码
                material_child_dictcode: '',
                // 原材料子类编码
                material_child_code: '',
                // 原材料子类名称
                material_child_name: '',
                // 原材料子类待选数据
                materialChildData: [],

                // 原材料规格绑定字段
                material_binding: '',
                // 原材料规格编码
                material_code: '',
                // 原材料规格名称
                material_name: '',
                // 原材料规格待选数据
                materialData: [],

                // 配比用量
                theory_quantity: 0,
                // 配比用量最大值
                theory_quantity_max: 0,
                // 配比用量最小值
                theory_quantity_min: 0,
                // 货源地编码
                source_goods_code: '',
                // 货源地名称
                source_goods_name: '',
                // 排序
                sort_num: 0,
            };
            this.tableData.splice(index + 1, 0, newData);
            // 计算容重、水胶比、砂率
            this.calculation();
        },
        // 新增
        addChange() {
            this.tableData.push({
                id: null,
                // 原材料分类绑定字段
                stock_category_binding: '',
                // 原材料分类字典码
                stock_category_dictcode: '',
                // 原材料分类编码
                stock_category: '',
                // 原材料分类名称
                stock_category_name: '',

                // 原材料子类绑定字段
                material_child_binding: '',
                // 原材料子类字典码
                material_child_dictcode: '',
                // 原材料子类编码
                material_child_code: '',
                // 原材料子类名称
                material_child_name: '',
                // 原材料子类待选数据
                materialChildData: [],

                // 原材料规格绑定字段
                material_binding: '',
                // 原材料规格编码
                material_code: '',
                // 原材料规格名称
                material_name: '',
                // 原材料规格待选数据
                materialData: [],

                // 配比用量
                theory_quantity: 0,
                // 配比用量最大值
                theory_quantity_max: 0,
                // 配比用量最小值
                theory_quantity_min: 0,
                // 货源地编码
                source_goods_code: '',
                // 货源地名称
                source_goods_name: '',
                // 排序
                sort_num: 0,
            });
        },
        // 配比用量
        theoryQuantity(data) {
            let isTheoryQuantity = false;
            if (Number(data.theory_quantity) < Number(data.theory_quantity_min)
                || Number(data.theory_quantity) > Number(data.theory_quantity_max)) {
                isTheoryQuantity = true;
            }
            return isTheoryQuantity;
        },
        // 最小值
        Minimum(data) {
            let isMin = false;
            if (data.theory_quantity_min !== null && data.theory_quantity_min_yyq !== null) {
                if (Number(data.theory_quantity_min) < Number(data.theory_quantity_min_yyq)) {
                    isMin = true;
                }
            }
            return isMin;
        },
        // 最大值
        Maximum(data) {
            let isMax = false;
            if (data.theory_quantity_max !== null && data.theory_quantity_max_yyq !== null) {
                if (Number(data.theory_quantity_max) > Number(data.theory_quantity_max_yyq)) {
                    isMax = true;
                }
            }
            return isMax;
        },
        // 保存实验配比基本信息
        theoryFormulaSave() {
            let isSave = true;
            for (let i = 0; i < this.tableData.length; i++) {
                this.tableData[i].sort_num = i;

                // if (this.tableData[i].theory_quantity < 1) {
                //     const message = '请填写原材料[ ' + this.tableData[i].stock_category_name + ' ]的配比用量!';
                //     this.$message({
                //         message: message,
                //         type: 'warning',
                //     });
                //     isSave = false;
                //     return;
                // }
                if (this.tableData[i].theory_quantity_max < 1) {
                    const message = '请填写原材料[ ' + this.tableData[i].stock_category_name + ' ]配比用量最大值!';
                    this.$message({
                        message: message,
                        type: 'warning',
                    });
                    isSave = false;
                    return;
                }
            }

            if (isSave === false) {
                return;
            }

            let specialRequire = '';
            if (this.theoryFormula.select_special_require.length > 0) {
                this.theoryFormula.select_special_require.forEach(item => {
                    specialRequire += item + ',';
                });
            }
            specialRequire = specialRequire.substring(0, specialRequire.length - 1);
            this.theoryFormula.special_require = specialRequire;

            this.isLoading = true;
            this.$axios
                .post('/interfaceApi/production/theoryformula/save', {
                    theoryFormula: this.theoryFormula,
                    theoryFormulaEntity: this.tableData,
                })
                .then(res => {
                    if (res.is_control ) {
                        this.$alert(`${res.control_msg}`, '管控提醒', {
                            dangerouslyUseHTMLString: true,
                            type: 'warning',
                            confirmButtonText: '确定',
                        });
                    }
                    const requestUrl = `/interfaceApi/production/theoryformula/formulawithentity/${res.formula_number}`;
                    this.getInitData(requestUrl);
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.$message.success('保存成功！');
                    // 是否以保存
                    this.isSave = true;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.isLoading = false;
                });
        },
        // 发布
        release() {
            this.isLoading = true;
            this.$axios
                .put(`/interfaceApi/production/theoryformula/publish_formula/${this.theoryFormula.theory_formula_number}`)
                .then(res => {
                    if (res.is_control ) {
                        this.$alert(`${res.control_msg}`, '管控提醒', {
                            dangerouslyUseHTMLString: true,
                            type: 'warning',
                            confirmButtonText: '确定',
                        });
                    }
                    this.$message.success('发布成功！');
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    // 是否已发布
                    this.is_publish = true;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="stylus">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none;
input[type="number"]
    -moz-appearance: textfield;
.main_top
    table
        width 100%;
        box-sizing border-box
        border-collapse collapse
        th,td
            border 2px solid #ffffff
            font-size 0.14rem
            line-height 0.4rem
            font-weight 400
            background #F8F8F8
            border-bottom none !important
        th
            width 1.1rem
            text-align right
        td
            width 2.4rem
            color #333;
            background #fff;
            .readonly_input
                >>> .el-input__inner
                    background #F8F8F8  !important
            >>> .el-radio-group
                white-space nowrap
                .el-radio
                    margin-right 0.15rem
            >>> .el-input__inner
                padding-left .05rem
            >>> .is-disabled .el-input__inner
                color #333;
                background-color #F8F8F8
.theorymatching
    width 100%
    height calc(100%)
    display flex
    flex-direction column
    padding .1rem
    .el-button:hover
        color: white !important;
        font-weight: bold;
    .el-button:focus
        color: white !important;
        font-weight: bold;
    .main_box
        height 100%
    .head_title_container
        padding-bottom 0.09rem !important
    .el-radio__input
        &.is-disabled
            &+span.el-radio__label
                color #606266
    .el-input
        &.is-disabled
            .el-input__inner
                color #606266
    .el-dialog
        height 60% !important
    .el-table__row
        td
            padding 0
            height 0.34rem
    .el-dialog__wrapper
        height 100% !important
    .header_wrapper
        width 100%
        background #ffffff
        overflow hidden
        border 1px solid #D7D7D7
        .header_left
            float left
            width 100%
            height 100%
            display flex
            .input_item
                margin-right 0.05rem
                width 33.3%
                padding .02rem
                background #ffffff
                display flex
            .header_span
                font-size 0.16rem
                display inline-block
                width 23%
                display flex
                align-items center
                background #F8F8F8
            .item_input
                width 77%
            .el-input__inner
                height 0.36rem !important
                line-height 0.36rem
        .el-button
            width 0.6rem
            height 0.34rem
            padding 0
            font-size 0.16rem
            background #1577d2
            margin-left 0.2rem
    .main_wrapper
        display flex
        margin-top .1rem
        .main_top
            margin-right .08rem
            display flex
            flex-direction column
            width 45%
            .product_marking_wrapper
                width 100%
                background #ffffff
                border 1px solid #D7D7D7
                margin-bottom .1rem
                p
                    font-size 0.18rem
                    border-left 0.04rem solid #1577D2
                    padding-left 0.1rem
                    font-weight 800
                ul, li
                    line-height 0.5rem
                    span
                        font-size 0.16rem
                    >span
                        padding-left 0.1rem
                    .el-select
                        width calc(100% - 1rem)
                        .el-tag--small
                            height 0.24rem
                            line-height 0.22rem
                li
                    display flex
            .basic_info_wrapper
                height 100%
                background #ffffff
                border 1px solid #D7D7D7
                .basic_info_box
                    display flex
                    justify-content space-between
                    >div
                        width 50%
                    th
                        width 1.3rem !important
                p
                    font-size 0.18rem
                    border-left 0.04rem solid #1577D2
                    padding-left 0.1rem
                    font-weight 800
                ul, li
                    line-height 0.5rem
                    .item_title
                        font-size 0.16rem
                        width 1.3rem
                        display inline-block
                        padding-left 0.1rem
                li
                    display flex
        .main_bottom
            width 55%
            max-height 6rem
            background #ffffff
            display flex
            flex-direction column
            .main_header
                width 100%
                margin-bottom 0.1rem
                border-bottom: 2px solid #0062b5;
                height .35rem
                position relative
                .production_wrapper
                    width 8rem
                    float left
                .production_span
                    font-size 0.16rem
                    float left
                    line-height 0.34rem
                .radio_list
                    .el-radio
                        height 0.34rem
                        margin-right 0.1rem !important
                        line-height 0.1rem
                    .el-radio__input
                        display none
                    .el-radio__label
                        padding 0
                        width 100%
                        height 100%
                        line-height 0.1rem
                    .is-bordered
                        padding 0.1rem
                .btn_wrapper
                    margin-left 0.4rem
                    position absolute
                    top 0
                    right 0
                .el-button
                    height: 0.27rem;
                    padding: 0.05rem .1rem;
                    font-size: 0.16rem;
                    background: #1577d2;
            .main_content
                display flex
                flex 1
                .content_left
                    width 100%
                    .el-table
                        font-size 0.16rem
                        height calc(100%) !important
                        th
                            background #F8F8F8 !important
                            border 2px solid #fff !important
                        td
                            border 1px solid #fff !important
                        .el-table__header-wrapper, .el-table__header
                            table-layout inherit
                        .cell
                            padding 0
                            text-align center
                        .el-table__body-wrapper, .el-table__body
                            table-layout inherit
                            .el-table__row, td
                                height 0.34rem
                                padding 0
                                .cell
                                    padding 0
                                    text-align center
                                    .el-input
                                        input
                                            text-align center
                                        &.iconfont
                                            position relative
                                            &:before
                                                position absolute
                                                top 50%
                                                left 0.05rem
                                                font-size 0.16rem
                                                color red
                                                transform: translateY(-55%);
                                            input
                                                color red
                        .el-table__empty-block
                            width 100%
    .header_right
        height .85rem
        display flex
        justify-content center
        align-items center
</style>
<style  lang="stylus" scoped>
    >>> .el-icon-arrow-up:before
        content: "\e78f" !important;
    >>> .el-input__suffix
        background #EFF4FF !important
        right 1px !important
    .main_wrapper
        >>> .el-input__suffix
            top 3px !important
</style>